import React, { useContext } from 'react';
import { PageProps, graphql } from 'gatsby';
import SEO from '@/components/seo';
import UIBox from '@/components/UI/UIBox';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import { Context } from '@/components/Layout';

import * as pageHeader from '@/styles/object/page-header.module.scss';

const ContactIndex: React.FC<PageProps<GatsbyTypes.ContactIndexQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);

  return (
    !loading && (
      <>
        <SEO title="お問い合わせ" />
        <div className="page-wrap --common">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeader.page_header}>
            <h1 className={pageHeader.page_header__title}>
              <small className={pageHeader.page_header__head_text}>
                お問い合わせ
              </small>
              <div className={pageHeader.page_header__text}>Contact</div>
            </h1>
          </header>
          <div className="page-contents-box">
            <div className="section">
              <p className="text-01">
                当社へのお問い合わせ、お申し込みは、以下の窓口よりお受けしております。
              </p>
            </div>
            <div className="section">
              <div className="box-2rows">
                {data.allMicrocmsContact.edges.map(
                  ({ node }) =>
                    node.display && (
                      <UIBox
                        key={node.contactId}
                        title={node.title}
                        description={`${node.button_text}`}
                        link={`/contact/${node.contactId}/`}
                      />
                    ),
                )}
              </div>
            </div>
            <div className="section">
              <ul className="list-rect">
                <li className="list-rect__item">
                  お問い合わせいただいた内容や諸条件により回答できない場合、または、お時間がかかる場合がございます。あらかじめご了承ください。
                </li>
                <li className="list-rect__item">
                  当社への営業を目的としたお問い合わせには応じかねます。
                </li>
              </ul>
            </div>
            <div className="section">
              <div className="title-lv4">NRIネットコム株式会社</div>
              <p className="text-01">
                本社：06-7166-1520
                <br />
                東京本社：03-6274-1200
              </p>
            </div>
          </div>
        </div>
        <UIBreadcrumb UpperItem={[]} CurrentItem="Contact" />
      </>
    )
  );
};

export default ContactIndex;

export const query = graphql`
  query ContactIndex {
    allMicrocmsContact(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          title
          contactId
          button
          button_text
          tayori
          display
        }
      }
    }
  }
`;
